$color-text: #1b1b1b;
$color-gray: #373737;
$color-lightgray: #a5a5a5;
$color-white: #f5f5f5;
$color-bg: #fefffb;

#about1 {
  padding-top: 17.6rem;
  @include desktop {
    padding-top: 14.8rem;
  }
  @include tablet {
    padding-top: 15rem;
  }
  @media (max-width: 767px) {
    padding-top: 21rem;
  }
  img {
    width: 120rem;
    height: 59.3rem;
    display: block;
    margin: {
      left: auto;
      right: auto;
    }

    @include desktop_large {
      width: 100%;
      height: auto;
      margin-top: 14rem;
    }
    @include desktop {
      width: 82.6%;
      height: auto;
      margin-top: 14rem;
    }
    @include tablet {
      width: 120rem;
      height: auto;
      margin-top: 15rem;
    }
    @media (max-width: 767px) {
      width: 100%;
      height: auto;
      margin-top: 21.25rem;
    }

    @media (min-width: 1000px) and (max-width: 1320px) {
      width: 90%;
      margin: 0 auto;
    }
    @media (min-width: 769px) and (max-width: 883px) {
      width: 85%;
      margin: 0 auto;
    }
  }

  .section-wrapper {
    width: 120rem;
    margin: 0 auto;
    @include desktop {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .section-txt-wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 5.6rem 0 17.6rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include desktop_large {
      width: 90%;
      padding: 4.5rem 0 14.8rem 0;
    }
    @include desktop {
      width: 82.6%;
      padding: 4.5rem 0 14.8rem 0;
    }
    @include tablet {
      width: 114rem;
      padding: 4.2rem 0 17rem 0;
    }
    @media (max-width: 767px) {
      padding: 5.6rem 0 23rem 0;
      width: 117rem;
    }
    @media (min-width: 769px) and (max-width: 883px) {
      width: 85%;
      margin: 0 auto;
    }

    .txt-title {
      white-space: pre;
      font-size: 3.7rem;
      font-weight: 500;
      color: $color-text;
      width: 55%;
      @include desktop {
        font-size: 3.75rem;
        width: 42.6rem;
      }
      @include tablet {
        font-size: 5.1rem;
        width: 45rem;
        white-space: pre-wrap;
      }
      @media (max-width: 767px) {
        font-size: 4.58rem;
        white-space: pre-wrap;
        width: 40.8rem;
      }
    }
    .divider {
      color: $color-lightgray;
      border-left: 0.2rem solid;
      height: 17rem;
    }
    .txt-sub {
      font-size: 1.8rem;
      font-weight: 400;
      width: 38%;
      text-align: right;
      @include desktop {
        font-size: 2rem;
      }
      @include tablet {
        font-size: 2rem;
      }
      @media (max-width: 767px) {
        font-size: 2.5rem;
      }
    }
  }
  ul {
    width: 143rem;
    height: 14.1rem;
    margin: {
      top: 5.6rem;
      left: auto;
      right: auto;
    }
    padding: 0;
    li {
      float: left;
      &:first-child {
        font-size: 4rem;
        font-weight: 700;
        color: $color-text;
        margin-top: 42px;
      }
      &:nth-child(2) {
        color: $color-lightgray;
        border-left: 0.2rem solid;
        height: 17rem;
        margin-left: 31%;
      }
      &:last-child {
        font-size: 1.8rem;
        font-weight: 400;
        margin: {
          top: 0.78%;
          left: 5%;
        }
      }
    }
  }
}

//mobile

#m_about1 {
  overflow-x: hidden;
  margin-top: 11.6rem;
  .section-wrapper {
    .txt-title {
      margin: 0 4.37rem;
      color: $color-text;
      font-size: 3.75rem;
      font-weight: 700;
      margin-bottom: 5rem;
    }
    .txt-sub {
      margin: {
        left: 4.37rem;
        top: 5.41rem;
      }
      color: $color-text;
      font-size: 3.3rem;
      font-weight: 400;
    }
    .section-img-wrapper {
      img {
        width: 100%;
        height: 3.54rem;
      }
    }
  }
}
