@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import "./partial/header.scss";
@import "./partial/footer.scss";
@import "./partial/section01.scss";
@import "./partial/modal.scss";
@import "./partial/simplePopup.scss";
@import "./partial/accordion.scss";
@import "./partial/section02.scss";
@import "./partial/section03.scss";
@import "./partial/section04.scss";
@import "./partial/section05.scss";
@import "./partial/section06.scss";

@include mobile_mb {
  html {
    font-size: 30%;
  }
  button {
    -webkit-tap-highlight-color: transparent;
  }
}

@include mobile_landscape {
  html {
    font-size: 30%;
  }
}

@include tablet {
  html {
    font-size: 40%;
  }
}

@include desktop {
  html {
    font-size: 50%;
  }
}

@include desktop_large {
  html {
    font-size: 62.5%;
  }
}

$color-text: #1b1b1b;
$color-gray: #373737;
$color-lightgray: #a5a5a5;
$color-white: #f5f5f5;
$color-bg: #fefffb;

// html {
//   font-size: 62.5%;
//   // overflow-x: hidden;
// }
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background-color: $color-bg;
  min-width: 335px;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  white-space: pre-wrap;
}

.mouse-motion {
  position: absolute;
  width: 100%;
  bottom: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroll-txt {
  font-weight: 400;
  font-size: 1rem;
  color: white;
}

.main_title-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 18rem;
  margin: 0 auto;
  // @include mobile_mb_xsmall {
  //   flex-direction: column;
  //   align-items: center;
  //   width: 90%;
  // }
  // @include mobile_mb_small {
  //   flex-direction: column;
  //   align-items: center;
  //   width: 90%;
  // }
  @include mobile_mb {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  @include mobile_landscape {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  img {
    width: 78rem;
  }

  &.mb {
    flex-direction: column;

    .character {
      display: flex;
      justify-content: center;
      align-items: center;

      .inner {
        width: 80%;
      }
    }

    .main_title {
      display: flex;
      justify-content: center;
      align-items: center;

      .inner01 {
        width: 80%;
      }
    }
  }

  .main_title {
    padding-top: 10px;
  }
}

.menu {
  &.hb {
    cursor: pointer;
  }

  .item01,
  .item02,
  .item03,
  .item04,
  .item05 {
    cursor: pointer;
  }
}

.element {
  position: relative;
}

.container {
  padding: 140px 0;
}

.wrapper {
  width: 90%;
  margin: 0 auto;
}

.flex {
  display: flex;

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.between {
    justify-content: space-between;
  }

  &.flexend {
    align-items: flex-end;
  }
}

.mt-60 {
  margin-top: 60px;
  @include mobile_mb {
    margin-top: 6rem;
  }
}

.mb-03 {
  margin-bottom: 0.3rem;
}
.mb-10 {
  margin-bottom: 1rem;
}

.mb-30 {
  margin-bottom: 3rem;
}

#dim {
  background: #000;
  opacity: 0.5;
  position: fixed;
  /* important to use fixed, not absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 9999;
  /* may not be necessary */
}
