@import "./mixins";
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
$color-text: #1b1b1b;
$color-gray: #373737;
$color-lightgray: #a5a5a5;
$color-white: #f5f5f5;
$color-bg: #fefffb;

@include desktop {
  .accordion__section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }
}

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  // width: 1200px;
  margin: 0 auto;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #f8f8f8;
  color: #444;
  cursor: pointer;
  padding: 1.8rem;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  border-bottom: 1px solid $color-lightgray;
  border-radius: 0;
  &.pop {
    background-color: transparent;
    border: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    .accordion__icon {
      // margin-left: 0.6rem;
      margin: 0;
      transition: transform 0.1s ease;
    }
    p {
      font-family: "Spoqa Han Sans Neo", "sans-serif";
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      color: $color-text;
      padding: 12px;
    }
  }
}

.language-list {
  p {
    font-family: "Spoqa Han Sans Neo", "sans-serif";
    font-weight: 400;
    font-size: 200rem;
    text-align: center;
    color: $color-lightgray;
    padding: 1.2rem;
    &.slected {
      font-weight: 800;
      color: $color-text;
      position: relative;
      &::after {
        content: "";
        width: 0.6rem;
        height: 0.6rem;
        position: absolute;
        top: 1.2rem;
      }
    }
  }
}

.language-list {
  &.pop {
    p {
      font-family: "Spoqa Han Sans Neo", "sans-serif";
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: $color-gray;
      padding: 12px;
      &.slected {
        font-weight: 700;
        color: $color-text;
        position: relative;
        &::after {
          content: "";
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          position: absolute;
          top: 1.2rem;
        }
      }
    }
  }
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
// .accordion:hover,
// .active {
//   //   background-color: #ccc;
// }

/* Style the accordion content title */
.accordion__title {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  font-weight: 600;
  font-size: 1.4rem;
  // @include mobile_mb {
  //   font-size: 2.4rem;
  // }
  // @include mobile_landscape {
  //   font-size: 2.4rem;
  // }
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.1s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: rgba(72, 76, 83, 0.07);
  overflow: hidden;
  transition: max-height 0.1s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  font-weight: 400;
  font-size: 1.4rem;
  padding: 1.8rem;
  @include mobile_mb {
    padding: 2.5rem;
  }
}
