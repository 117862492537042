@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import "./mixins";

$color-text: #1b1b1b;
$color-gray: #373737;
$color-lightgray: #a5a5a5;
$color-white: #f5f5f5;
$color-bg: #fefffb;

.modal-btn_close {
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0;
  transform: translate(0, -50%);
  img {
    width: 3.8rem;
  }
}

.top-wrapper {
  position: relative;
  text-align: center;
  margin: 2rem 2rem 0 2rem;
  @include desktop {
    margin: 2rem 2rem 0 2rem;
  }
  @include tablet {
    margin: 2rem 2rem 0 2rem;
  }
  @media (max-width: 767px) {
    margin: 3rem 3rem 0 3rem;
  }
}
.modal-title {
  h2 {
    font-family: "Spoqa Han Sans Neo", "sans-serif";
    font-weight: 800;
    font-size: 2rem;
    line-height: 3.8rem;
    color: $color-text;
    @include desktop {
      font-size: 2rem;
      line-height: 3.8rem;
    }
    @include tablet {
      font-size: 2rem;
      line-height: 3.8rem;
    }
    @media (max-width: 767px) {
      font-size: 4rem;
      line-height: 6rem;
    }
  }
}

.modal-contents {
  width: 100%;
  // display: flex;
  // justify-content: center;
  .contents-txt {
    text-align: center;
    font-family: "Spoqa Han Sans Neo", "sans-serif";
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: $color-gray;
    padding: 6rem 0;
    border-bottom: 1px solid $color-lightgray;
    @include desktop {
      font-size: 1.6rem;
      line-height: 2.6rem;
    }
    @include tablet {
      font-size: 1.6rem;
      line-height: 2.6rem;
    }
    @media (max-width: 767px) {
      font-size: 3rem;
      line-height: 4rem;
    }
  }
  .language-list {
    // padding: 0 20px;
    background-color: $color-bg;
    padding-bottom: 10px;
    button {
      width: 90%;
      height: 6rem;
      display: block;
      margin: 0 auto;
      text-align: center;
      border-bottom: 1px solid $color-lightgray;
      padding: 0;
      border-radius: 0;
      transition: ease-in-out 0.35s;
      @include desktop {
        height: 6rem;
      }
      @include tablet {
        height: 6rem;
      }
      @media (max-width: 767px) {
        height: 10rem;
      }
      // &:hover {
      //   width: 100%;
      //   border-bottom: 1px solid #55aaa3;
      //   color: #303030;
      // }
      &.last-btn {
        border: none;
      }
      &.selected {
        border-bottom: 1px solid red;
        font-weight: 800;
        color: black;
      }
      p {
        font-family: "Spoqa Han Sans Neo", "sans-serif";
        font-weight: 400;
        color: $color-gray;
        font-size: 1.4rem;
        @include desktop {
          font-size: 1.4rem;
          line-height: 2.6rem;
        }
        @include tablet {
          font-size: 1.4rem;
          line-height: 2.6rem;
        }
        @media (max-width: 767px) {
          font-size: 3rem;
          line-height: 5rem;
        }
        &.slected {
          font-weight: 700;
          color: $color-text;
          position: relative;
          @include desktop {
            font-size: 1.4rem;
            line-height: 2.6rem;
          }
          @include tablet {
            font-size: 1.4rem;
            line-height: 2.6rem;
          }
          @media (max-width: 767px) {
            font-size: 3rem;
            line-height: 5rem;
          }
          &::after {
            content: "";
            width: 0.4rem;
            height: 0.4rem;
            position: absolute;
            top: 0.5rem;
            background-color: $color-bg;
          }
        }
      }
      p:hover {
        color: $color-text;
        font-weight: 600;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 36px 0;
    padding: 0;

    .modal-list {
      position: relative;
      list-style: none;
      width: 120px;
      height: 200px;
      display: inline-block;
      margin: 0 20px;
      z-index: 1;
      cursor: pointer;

      &:nth-child(1) {
        margin-bottom: 40px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        width: 120px;
        height: 200px;
        background: grey;
        z-index: -1;
      }
    }
  }
}

.rsm-z-40 {
  z-index: 9999 !important;
}

.md\:rsm-w-10\/12 {
  width: 54rem !important;
  min-height: auto !important;
}

.rsm-p-8 {
  padding: 0px !important;
}

.rsm-bg-black {
  background: black !important;
}

.rsm-opacity-70 {
  opacity: 0.7 !important;
}

.rsm-z-999 {
  z-index: 999 !important;
}

.rsm-z-9999 {
  z-index: 9999 !important;
}
