@import "./variables";

@mixin mobile_mb {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

// @mixin mobile_mb_small {
//   @media (min-width: #{$breakpoint-mobile-small}) and (max-width: #{$breakpoint-mobile - 1px}) {
//     @content;
//   }
// }

// @mixin mobile_mb {
//   @media (min-width: #{$breakpoint-mobile}) and (max-width: #{$breakpoint-mobile-landscape - 1px}) {
//     @content;
//   }
// }
@mixin mobile_landscape {
  @media (min-width: #{$breakpoint-mobile-landscape}) and (max-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) and (max-width: #{$breakpoint-desktop-large - 1px}) {
    @content;
  }
}

@mixin desktop_large {
  @media (min-width: #{$breakpoint-desktop-large}) {
    @content;
  }
}

// @mixin base_font_size {
//   @content;
// }
