@import "./mixins";

$color-text: #1b1b1b;
$color-gray: #373737;
$color-lightgray: #a5a5a5;
$color-white: #f5f5f5;
$color-bg: #fefffb;

#visual {
  background: url(../images/visual.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: -25px;
  .section-wrapper {
    width: 79.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    .section-img-wrapper {
      img {
        width: 30rem;
        height: 100%;
        @include desktop {
          width: 37.5rem;
          height: 7.72rem;
        }
        @include tablet {
          width: 38.75rem;
          height: 7.9rem;
        }
        @media (max-width: 767px) {
          width: 44.3rem;
          height: 9.16rem;
        }
      }
    }
    .section-txt-wrapper {
      margin-top: 3.2rem;
      @include desktop {
        margin-top: 2.5rem;
      }
      @include tablet {
        margin-top: 2.6rem;
      }
      @media (max-width: 767px) {
        margin-top: 2.7rem;
      }
      .txt-title {
        font-size: 2.5rem;
        font-weight: 400;
        color: $color-text;
        letter-spacing: 0.02rem;
        @include desktop {
          font-size: 3.1rem;
          padding-left: 0.6rem;
        }
        @include tablet {
          font-size: 3.1rem;
          padding-left: 1rem;
        }
        @media (max-width: 767px) {
          font-size: 3.75rem;
        }
      }
      .section-txt-wrapper2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 30.1rem;
        @include desktop {
          width: 39rem;
        }
        @include tablet {
          width: 39.3rem;
        }
        @media (max-width: 767px) {
          width: 45.6rem;
        }
        .txt-sub {
          font-size: 2rem;
          font-weight: 300;
          color: $color-text;
          display: inline;
          @include desktop {
            font-size: 2.25rem;
          }
          @include tablet {
            font-size: 2.5rem;
          }
          @media (max-width: 767px) {
            font-size: 2.9rem;
          }
        }
        .divider {
          border-bottom: 1px solid;
          color: $color-text;
          width: 9.5rem;
          margin: 0 1rem;
          @include desktop {
            width: 17.25rem;
          }
          @include tablet {
            width: 14rem;
            margin: 0 1rem;
          }
          @media (max-width: 767px) {
            width: 13.9rem;
            border: 0.7px solid;
            margin: 0 3rem;
          }
        }
      }
    }
  }
}

//mobile

#m_visual {
  margin-top: -24px;
  width: 100%;
  height: 100vh;
  background: url(../images/m_visual.jpg) no-repeat;
  background-size: cover;
  .section-wrapper {
    padding-top: 26rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.9rem;
    .section-img-wrapper {
      width: 30rem;
      height: 6.25rem;
    }
    .section-txt-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.625rem;
      .txt-title {
        font-size: 4.16rem;
        font-weight: 400;
        color: $color-text;
      }
      .section-txt-wrapper2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        .txt-sub {
          font-size: 3.75rem;
          font-weight: 300;
        }
        .divider {
          border-bottom: 1px solid;
          color: $color-text;
          width: 9rem;
          margin: 0 1rem;
        }
      }
    }
  }
}
