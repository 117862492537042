@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

$color-text: #1b1b1b;
$color-gray: #373737;
$color-lightgray: #a5a5a5;
$color-white: #f5f5f5;
$color-bg: #fefffb;

.lang_hm {
  width: 25px;
  height: 25px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  top: 0;
  max-width: 100%;
  padding: 82px 5% 28px 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .menu {
    width: 100%;
    h3 {
      font-family: "Spoqa Han Sans Neo", "sans-serif";
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      color: $color-gray;
      // padding: 12px;
      padding: 20px;
    }
    button {
      width: 100%;
      height: 60px;
      // display: block;
      margin: 0 auto;
      text-align: center;
      // border-bottom: 1px solid #a5aab3;
      padding: 0;
      border-radius: 0;
      transition: ease-in-out 0.35s;
    }
  }
}

.modal-header {
  position: absolute;
  top: 56px;
  width: 100%;
  height: 1px;
  background-color: $color-gray;
}

.modal-close-button {
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
}

button {
  font-size: 14px;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
}

.button-default {
  background: $color-text;
  color: #fff;
}
