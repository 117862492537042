@import "./mixins";

$color-text: #1b1b1b;
$color-gray: #373737;
$color-lightgray: #a5a5a5;
$color-white: #f5f5f5;
$color-bg: #fefffb;

#c_tv {
    .section-wrapper {
        width: 120rem;
        padding-top: 15rem;
        margin: 0 auto 0 auto;
        @include desktop {
            width: 124rem;
            padding-top: 14.8rem;
        }
        @include tablet {
            width: 120rem;
            padding-top: 17rem;
        }
        @media (max-width: 767px) {
            width: 121.8rem;
            padding-top: 23rem;
        }
        .section-txt-wrapper {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .txt-title {
                margin: 0 auto;
                font-size: 3.7rem;
                font-weight: 500;
                margin-bottom: 2.3rem;
                @include desktop {
                    font-size: 4.3rem;
                    margin-bottom: 1.7rem;
                }
                @include tablet {
                    font-size: 5.4rem;
                    margin-bottom: 2.1rem;
                }
                @media (max-width: 767px) {
                    font-size: 4.58rem;
                    margin-bottom: 1.4rem;
                }
            }
            .txt-sub {
                font-size: 2.5rem;
                font-weight: 400;
                color: $color-text;
                margin-bottom: 7rem;
                @include desktop {
                    font-size: 2.25rem;
                    margin-bottom: 7.75rem;
                }
                @include tablet {
                    font-size: 2.8rem;
                    margin-bottom: 7.3rem;
                }
                @media (max-width: 767px) {
                    font-size: 2.9rem;
                    margin-bottom: 5.6rem;
                }
            }
            #youtube {
                width: 100%;
                height: 54.9rem;
                background: url(../images/youtube.jpg) no-repeat;
                background-size: 100%;
                margin-bottom: 30rem;
                div {
                    width: 0;
                    opacity: 0;
                }
                @media (max-width: 767px) {
                    margin-bottom: 30rem;
                }
                @media (min-width: 995px) and (max-width: 1320px) {
                    width: 90%;
                    margin: 0 auto;
                }
                @media (min-width: 769px) and (max-width: 883px) {
                    width: 85%;
                    margin: 0 auto;
                }
            }
        }
    }
}

//mobile
#m_c_tv {
    overflow-x: hidden;
    ul {
        padding: {
            left: 0;
        }
        margin: 0;
        text-align: center;
        li {
            &:nth-child(1) {
                font-size: 3.75rem;
                font-weight: 700;
                margin-bottom: 1.45rem;
            }
            &:nth-child(2) {
                font-size: 2.5rem;
                font-weight: 400;
                padding-bottom: 5rem;
            }
        }
    }
    a {
        div {
            background: url(../images/youtube.jpg) no-repeat;
            width: 90%;
            height: 30vh;
            background-size: cover;
            background-position: -90px 0;
            margin: {
                left: auto;
                right: auto;
            }
        }
    }
}
