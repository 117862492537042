@import "./mixins";

$color-text: #1b1b1b;
$color-gray: #373737;
$color-lightgray: #a5a5a5;
$color-white: #f5f5f5;
$color-bg: #fefffb;

#product1 {
    padding-top: 11.5rem;
    @include desktop {
        padding-top: 14.8rem;
    }
    @include tablet {
        padding-top: 17rem;
    }
    @media (max-width: 767px) {
        padding-top: 23rem;
    }

    .section-wrapper {
        width: 120rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 auto;
        gap: 2rem;
        @include desktop {
            width: 124rem;
            gap: 3.8rem;
        }
        @include tablet {
            width: 119.6rem;
            gap: 4.38rem;
        }
        @media (max-width: 767px) {
            width: 117.9rem;
            gap: 2rem;
        }
        @media (min-width: 1000px) and (max-width: 1320px) {
            width: 90%;
            margin: 0 auto;
        }
        @media (min-width: 769px) and (max-width: 883px) {
            width: 85%;
            margin: 0 auto;
        }
        ul {
            align-items: left;
            padding: 0;
            width: 38.5rem;
            li {
                width: 100%;
                font-size: 1.4rem;
                font-weight: 500;
                color: $color-text;
                @media (min-width: 1200px) and (max-width: 1319px) {
                    width: 385px;
                }
                img {
                    width: 38.6rem;
                    height: 38.6rem;
                    @media (min-width: 769px) and (max-width: 883px) {
                        width: 100%;
                        height: auto;
                    }
                    @media (min-width: 1000px) and (max-width: 1320px) {
                        width: 100%;
                        height: auto;
                    }
                }
                .btn {
                    font-size: 1.3rem;
                    @include desktop {
                        font-size: 1.75rem;
                    }
                    @include tablet {
                        font-size: 2.18rem;
                    }
                    @media (max-width: 767px) {
                        margin-top: 3.4rem;
                        font-size: 2.4rem;
                    }
                }
                a {
                    color: $color-text;
                    margin: 0;
                    &:hover {
                        font-weight: 600;
                    }
                    img {
                        width: 2.6rem;
                        height: 0.7rem;
                        margin-left: 1rem;
                        @include desktop {
                            width: 3rem;
                        }
                        @include tablet {
                            width: 4rem;
                        }
                        @media (max-width: 767px) {
                            width: 5rem;
                            height: 100%;
                        }
                    }
                }
            }
        }
        .txt-sub {
            white-space: nowrap;
            font-size: 2.5rem;
            font-weight: 500;
            color: $color-text;
            margin: 5rem 0 1.5rem 0;
            @include tablet {
                width: 35%;
            }
            @include mobile_landscape {
                width: 35%;
            }
            @media (max-width: 767px) {
                width: 35%;
            }
        }
        .txt-text {
            font-size: 1.4rem;
            font-weight: 400;
            color: $color-lightgray;
            height: 6.8rem;
            margin-bottom: 2.28rem;
            @include desktop {
                font-size: 1.75rem;
                height: 10.6rem;
            }
            @include tablet {
                white-space: pre-line;
                font-size: 2.18rem;
                height: 18rem;
                margin-bottom: 0;
            }
            @media (max-width: 767px) {
                white-space: pre-line;
                font-size: 2.5rem;
                height: 20rem;
                margin-bottom: 0;
            }
        }
    }
}

//mobile

#m_product1 {
    overflow-x: scroll;
    width: 100%;
    .section-wrapper {
        width: 900px;
        white-space: nowrap;
        overflow-x: scroll;
        padding: 13.3rem 3.3rem;
        display: flex;
        flex-direction: row;
        gap: 3.3rem;
        ul {
            display: block;
            float: left;
            margin: 0;
            padding: 0;
            width: 62.5rem;
            li {
                img {
                    width: 62.5rem;
                    height: 62.5rem;
                }
                .txt-sub {
                    font-size: 3.75rem;
                    font-weight: 700;
                    margin-top: 5rem;
                }
                .txt-text {
                    white-space: normal;
                    margin-top: 3.3rem;
                    font-size: 2.5rem;
                    font-weight: 400;
                    color: $color-lightgray;
                }
            }
        }
    }
}
