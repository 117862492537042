@import "./mixins";

$color-text: #1b1b1b;
$color-gray: #373737;
$color-lightgray: #a5a5a5;
$color-white: #f5f5f5;
$color-bg: #fefffb;

.lang_txt {
  font-size: 1.4rem;
  color: $color-text;
  @include desktop_large {
    width: 100%;
  }
  @include desktop {
    width: 90%;
  }
  @include tablet {
    width: 90%;
  }
  @media (max-width: 767px) {
    width: 90%;
  }
  @media (max-width: 576px) {
    width: 90%;
    font-size: 3.1rem;
  }
}
@media (max-width: 576px) {
  nav {
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-wrapper {
      width: 90%;

      .logo {
        position: relative;
        left: 0;
        opacity: 0;
      }

      .menu {
        &.hb {
          position: relative;
          right: 0;
        }

        h3 {
          padding: 1rem;
        }
      }
    }
  }
}
@include mobile_mb {
  nav {
    width: 360px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.snb-nav {
      width: 0;
      opacity: 0;
    }
    .nav-wrapper {
      width: 90%;

      .logo {
        position: relative;
        left: 0;
      }

      .menu {
        &.hb {
          position: relative;
          right: 0;
        }

        h3 {
          padding: 1rem;
        }
      }
    }
  }
  .snb-wrapper {
    width: 0;
    opacity: 0;
  }
}

@include mobile_landscape {
  nav {
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &.snb-nav {
      width: 0;
      opacity: 0;
    }
    .nav-wrapper {
      width: 90%;

      .logo {
        position: relative;
        left: 0;
      }

      .menu {
        &.hb {
          position: relative;
          right: 0;
        }

        h3 {
          padding: 1rem;
        }
      }
    }
  }
  .snb-wrapper {
    opacity: 0;
    width: 0;
  }
}

@include tablet {
  nav {
    position: fixed;
    justify-content: center;
    &.snb-nav {
      height: 100%;
      width: 50px;
    }
    .nav-wrapper {
      width: 90%;

      .logo {
        position: relative;
        left: 5%;
      }

      .menu {
        width: 50%;
        position: relative;
        right: 5%;

        h3 {
          padding: 1rem;
        }
      }
    }
  }
  .snb-wrapper {
    width: 50px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    .menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 15.6rem;
      .item01,
      .item02,
      .item03,
      .item04 {
        transform: rotate(90deg);
        color: $color-lightgray;
        font-weight: 700;
        // height: 10%;
        h3 {
          height: 10%;
          padding-bottom: 5px;
          &:hover {
            color: $color-gray;
          }
        }
        &.active {
          border-bottom: 1px solid $color-text;
          color: $color-text;
          font-weight: 800;
        }
      }
    }
  }
}

@include desktop {
  nav {
    justify-content: center;
    &.snb-nav {
      height: 100%;
      width: 50px;
    }
    .nav-wrapper {
      position: absolute;
      width: 90%;
      // padding: 0 20%;

      .logo {
        position: relative;

        // left: 5%;
      }

      .menu {
        width: 50%;
        position: relative;
        right: 5%;
        padding: 0;

        h3 {
          padding: 1rem;
        }
      }
    }
  }
  .snb-wrapper {
    height: 100vh;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    left: 5px;
    .menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 100px;
      .item01,
      .item02,
      .item03,
      .item04 {
        transform: rotate(90deg);
        color: $color-lightgray;
        font-weight: 700;
        // height: 10%;
        h3 {
          height: 10%;
          padding-bottom: 5px;
          &:hover {
            color: $color-gray;
          }
        }
        &.active {
          border-bottom: 1px solid $color-text;
          color: $color-text;
          font-weight: 800;
        }
      }
    }
  }
}

@include desktop_large {
  nav {
    justify-content: center;
    &.snb-nav {
      height: 100%;
      width: 50px;
    }

    .nav-wrapper {
      position: absolute;
      width: 1200px;

      .menu {
        width: 50%;
        align-items: center;
        padding-right: 50px;

        h3 {
          padding: 1rem;
        }
      }
    }
  }
  .snb-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    left: 10px;
    .menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 12.5rem;
      .item01,
      .item02,
      .item03,
      .item04 {
        transform: rotate(90deg);
        color: $color-lightgray;
        font-weight: 700;
        // height: 10%;
        h3 {
          height: 10%;
          padding-bottom: 5px;
          &:hover {
            color: $color-gray;
          }
        }
        &.active {
          border-bottom: 1px solid $color-text;
          color: $color-text;
          font-weight: 800;
        }
      }
    }
  }
}

nav {
  position: fixed;
  height: 56px;
  z-index: 1060;
  top: 0;
  display: flex;
  width: 100%;
  &.mb {
    width: 100%;
    .nav-wrapper {
      width: 90%;
      margin: 0 auto;
    }
  }

  .nav-wrapper {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .lang {
      position: absolute;
      right: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 1.8rem;
        @media (max-width: 576px) {
          width: 3.1rem;
          height: 3.1rem;
        }
      }
    }

    .logo {
      position: absolute;
      left: 0;
      cursor: pointer;
      @include mobile_mb {
        img {
          width: 14.5rem;
        }
      }
      @include mobile_landscape {
        img {
          width: 14.5rem;
        }
      }
      @include tablet {
        img {
          width: 6.9rem;
        }
      }
      @include desktop {
        img {
          width: 6.9rem;
        }
      }
      @include desktop_large {
        img {
          width: 6.9rem;
        }
      }
    }

    .menu {
      display: flex;
      justify-content: flex-end;
      // padding-right: 50px;
    }
  }
}
