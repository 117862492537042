@import "./mixins";

$color-text: #1b1b1b;
$color-gray: #373737;
$color-lightgray: #a5a5a5;
$color-white: #f5f5f5;
$color-bg: #fefffb;
#product2 {
  background: url(../images/product2_bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  @media (max-width: 767px) {
    height: 70vh;
  }
  margin-top: 17.5rem;
  .section-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 28rem;
    @include desktop {
      gap: 16rem;
    }
    @include tablet {
      gap: 16.7rem;
    }
    @media (max-width: 767px) {
      gap: 1rem;
    }
    .section-img-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: 100%;

      img {
        width: auto;
        height: 90vh;
        @include mobile_landscape {
          height: 60vh;
          width: auto;
        }
        @media (min-width: 1200px) and (max-width: 1280px) {
          margin-left: 30px;
        }
      }
    }
    .section-txt-wrapper {
      display: flex;
      flex-direction: column;
      @include desktop {
        width: 38.7rem;
      }
      @include tablet {
        width: 44rem;
      }
      @media (max-width: 767px) {
        width: 40rem;
      }
      .txt-title {
        font-size: 3.5rem;
        font-weight: 500;
        color: $color-text;
        margin-bottom: 2.3rem;
        @include desktop {
          font-size: 2.5rem;
          margin-bottom: 1.6rem;
        }
        @include tablet {
          font-size: 3.4rem;
          margin-bottom: 2.3rem;
        }
        @media (max-width: 767px) {
          font-size: 3.75rem;
          margin-bottom: 3.7rem;
        }
      }
      .txt-sub {
        white-space: pre-line;
        font-size: 2.2rem;
        font-weight: 400;
        color: $color-text;
        @include desktop_large {
          width: 55.4rem;
          font-size: 2rem;
        }
        @include desktop {
          width: 40rem;
          font-size: 2rem;
        }
        @include tablet {
          font-size: 2.5rem;
          width: 40rem;
        }
        @media (max-width: 767px) {
          font-size: 2.9rem;
          width: 41rem;
        }
      }
    }
  }
  #model {
    z-index: 5;
    white-space: nowrap;
    float: right;
    margin: -6.5rem 6.5rem 0 0;
    font-size: 1.6rem;
    font-weight: 400;
    color: $color-gray;
  }
}

//mobile
#m_product2 {
  background: url(../images/m_product_bg.jpg) no-repeat;
  background-size: 100%;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 13.3rem;
  .section-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    .section-txt-wrapper {
      padding-top: 11.8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: $color-text;
      .txt-title {
        font-size: 3.75rem;
        font-weight: 700;
        margin-bottom: 6.6rem;
      }
      .txt-sub {
        font-size: 3.3rem;
        font-weight: 400;
      }
    }
    .section-img-wrapper {
      width: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
