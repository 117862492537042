@import "./mixins";

$color-text: #1b1b1b;
$color-gray: #373737;
$color-lightgray: #a5a5a5;
$color-white: #f5f5f5;
$color-bg: #fefffb;

#about2 {
    .section-img-wrapper {
        background: url(../images/about2.jpg) fixed;
        width: 100%;
        height: 55.8rem;
        background-size: cover;
        @include desktop {
            height: 37rem;
        }
        @include tablet {
            height: 38.4rem;
        }
        @media (max-width: 767px) {
            height: 39.75rem;
        }
    }
    .section-wrapper {
        width: 120rem;
        margin: 0 auto;
        @include desktop {
            width: 124rem;
        }
        @include tablet {
            width: 120rem;
        }
        @media (max-width: 767px) {
            width: 118rem;
            height: 38.75rem;
        }
        @media (min-width: 1000px) and (max-width: 1320px) {
            width: 90%;
        }
        .txt-title {
            white-space: pre;
            color: $color-text;
            margin: {
                top: 17.6rem;
                bottom: 11.7rem;
            }
            font-size: 2.5rem;
            font-weight: 500;
            @include desktop {
                margin-top: 12.7rem;
                font-size: 2.5rem;
                margin-bottom: 7.75rem;
            }
            @include tablet {
                font-size: 3.1rem;
                margin-top: 11.95rem;
                margin-bottom: 7.9rem;
            }
            @media (max-width: 767px) {
                font-size: 4rem;
                margin-top: 12.3rem;
                margin-bottom: 7rem;
            }
        }
        .section-txt-wrapper {
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 9rem;
            @include desktop {
                justify-content: space-between;
            }
            @include tablet {
                justify-content: space-between;
            }
            @media (max-width: 767px) {
                justify-content: space-between;
            }
            @media (min-width: 1000px) and (max-width: 1320px) {
                width: 100%;
                margin: 0 auto;
                justify-content: space-between;
                gap: normal;
            }
        }
        .item {
            @include tablet {
                width: 26rem;
            }
            @media (max-width: 767px) {
                width: 26rem;
            }
            .txt-sub {
                color: $color-text;
                font-size: 1.8rem;
                font-weight: 700;
                margin-bottom: 1.4rem;
                @include desktop {
                    font-size: 2.25rem;
                }
                @include tablet {
                    font-size: 2.8rem;
                }
                @media (max-width: 767px) {
                    font-size: 2.9rem;
                }
            }
            .txt-text {
                white-space: pre-line;
                color: $color-text;
                font-size: 1.4rem;
                font-weight: 400;
                width: 23rem;
                @include desktop {
                    white-space: normal;
                    font-size: 1.75rem;
                }
                @include tablet {
                    white-space: normal;
                    font-size: 2.18rem;
                }
                @media (max-width: 767px) {
                    white-space: normal;
                    font-size: 2.8rem;
                }
            }
        }
    }
}

#line {
    border-bottom: 1px solid;
    color: $color-lightgray;
    width: 120rem;
    margin: 11.5rem auto 0 auto;
    @include desktop {
        width: 124rem;
        margin: 14.8rem auto 0 auto;
    }
    @include tablet {
        width: 120rem;
        margin: 17.03rem auto 0 auto;
    }
    @media (max-width: 767px) {
        width: 119.8rem;
        margin: 23rem auto 0 auto;
    }
}

//mobile

#m_about2 {
    margin-top: 11.6rem;
    background: url(../images/m_about2.jpg) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .section-wrapper {
        width: 90%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .txt-title {
            font-size: 3.75rem;
            font-weight: 800;
            text-align: center;
        }
        .section-txt-wrapper {
            display: flex;
            height: 60vh;
            flex-direction: column;
            justify-content: space-between;
            .item {
                .txt-sub {
                    font-size: 3.75rem;
                    font-weight: 700;
                    color: $color-text;
                    margin-bottom: 2.5rem;
                    text-align: center;
                }
                .txt-text {
                    text-align: center;
                    font-size: 3.3rem;
                    font-weight: 400;
                    color: $color-text;
                }
            }
        }
    }
}

/*
    .section-wrapper {
        padding: 0 3.3rem;
    }
    .txt-title {
        font-size: 3.75rem;
        font-weight: 800;
        text-align: center;
    }
    .section-txt-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .item {
            align-items: center;
            .txt-sub {
                font-size: 3.75rem;
                font-weight: 700;
                color: $color-text;
                margin-bottom: 2.5rem;
                text-align: center;
            }
            .txt-text {
                text-align: center;
                font-size: 3.3rem;
                font-weight: 400;
                color: $color-text;
            }
        }
    }
}
*/
