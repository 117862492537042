$color-text: #1b1b1b;
$color-gray: #373737;
$color-lightgray: #a5a5a5;
$color-white: #f5f5f5;
$color-bg: #fefffb;

article {
  padding-top: 20.1rem;
  margin-bottom: -36px;
  #footer1 {
    border-top: 1px solid;
    border-color: $color-lightgray;
    padding: {
      top: 0.8rem;
      bottom: 0.8rem;
    }
    ul {
      width: 265px;
      height: 1.4rem;
      margin: {
        left: auto;
        right: auto;
      }
      li {
        float: left;
        &:hover {
          text-decoration: underline;
        }
        padding: {
          left: 10px;
          right: 10px;
        }
        font-size: 1.4rem;
        font-weight: 400;
        border-right: 1px solid;
        border-color: $color-gray;
        a {
          color: $color-gray;
        }
        &:last-child {
          border: 0;
        }
      }
    }
  }

  .footer2 {
    white-space: pre;
    width: 100%;
    background: $color-white;
    .section-wrapper {
      width: 120rem;
      margin: {
        left: auto;
        right: auto;
      }

      .section-txt-wrapper {
        padding: {
          top: 10rem;
          bottom: 10rem;
        }
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        color: $color-gray;
        line-height: 173.5%;
        @include tablet {
          justify-content: space-between;
        }
        @include mobile_mb {
          justify-content: space-between;
        }
        .section-img-wrapper {
          img {
            padding-right: 4.9rem;
          }
          @include tablet {
            width: 0;
          }
          @include mobile_mb {
            width: 0;
          }
        }
        div {
          &:nth-child(2) {
            flex-basis: 42rem;
          }
          &:nth-child(3) {
            align-self: flex-start;
          }
          &:last-child {
            padding-left: 1rem;
            align-self: flex-start;
            @include tablet {
              width: 23rem;
              white-space: pre-line;
            }
            @include mobile_mb {
              width: 0;
            }
          }
        }
        .txt-sub {
          font-weight: 700;
          padding-right: 3.3rem;
          @include tablet {
            padding: 0;
            padding-left: 10rem;
          }
          @include mobile_mb {
            padding: 0;
            padding-left: 10rem;
          }
        }
      }
    }
  }
}

//mobile

#m_footer {
  margin-top: 13.3rem;
  .topbtn {
    margin: 0 auto;
    width: 6rem;
    margin-bottom: 5.25rem;
  }
  .section-wrapper1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.6rem;
    padding: 2.5rem 0;
    border-top: 1px solid;
    border-color: $color-lightgray;
    div {
      border-right: 1px solid;
      a {
        color: $color-text;
        font-size: 2.5rem;
        font-weight: 700;
        padding-right: 1.5rem;
      }
      &:last-child {
        border: 0;
      }
    }
  }
}
.section-wrapper2 {
  background: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 6rem 0;
  margin-bottom: -36px;
  .section-txt-wrapper {
    .txt-text {
      &:first-child {
        font-weight: 700;
      }
      &:nth-child(2) {
        padding-bottom: 5.6rem;
      }
      &:nth-child(3) {
        padding-bottom: 5.6rem;
      }
      text-align: center;
      font-size: 2.5rem;
      font-weight: 400;
      color: $color-text;
      line-height: 173.5%;
    }
  }
  .section-img-wrapper {
    img {
      width: 17.7rem;
      height: 4.5rem;
    }
  }
}
